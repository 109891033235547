@import '../stylesheet';

/* START THEME
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_logo {
  // never more then width: 200px and height 60px
  svg.main-logo-lasfolladoras {width: 200px; height: 33px;}
  svg.main-logo-lacochonne {width: 200px; height: 33px;}
  svg.main-logo-castingfrancais {width: 175px; height: 60px;}
  svg.main-logo-lanovice {width: 155px; height: 25px;}
  svg.main-logo-castingallaitaliana {width: 150px; height: 60px;}
  svg.main-logo-hausfrauficken {width: 200px; height: 40px;}
  svg.main-logo-deutschlandreport {width: 200px; height: 33px;}
  svg.main-logo-reifeswinger {width: 200px; height: 50px;}
  svg.main-logo-scambistimaturi {width: 200px; height: 50px;}
  svg.main-logo-sextapegermany {width: 120px; height: 60px;}
  svg.main-logo-xxxomas {width: 125px; height: 45px;}
}

.module-footer {
  svg.main-logo-castingallaitaliana {width: 160px; height: 50px;}
  svg.main-logo-castingfrancais {width: 160px; height: 60px;}
  svg.main-logo-hausfrauficken {width: 160px; height: 30px;}
  svg.main-logo-reifeswinger {width: 160px; height: 40px;}
  svg.main-logo-scambistimaturi {width: 160px; height: 40px;}
  svg.main-logo-sextapegermany {width: 160px; height: 60px;}
  svg.main-logo-xxxomas {width: 160px; height: 40px;}
}

// RESPONSIVE - CUSTOM
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media screen and (min-width: 1000px) and (max-width: 1048px) {
  .module-header_logo svg.main-logo {width: 160px !important;}
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .module-header_logo {
    // never more then width: 170px and height 35px
    svg.main-logo-lasfolladoras {width: 170px; height: 28px;}
    svg.main-logo-lacochonne {width: 170px; height: 28px;}
    svg.main-logo-castingfrancais {width: 120px; height: 35px;}
    svg.main-logo-lanovice {width: 120px; height: 20px;}
    svg.main-logo-castingallaitaliana {width: 90px; height: 35px;}
    svg.main-logo-hausfrauficken {width: 170px; height: 30px;}
    svg.main-logo-deutschlandreport {width: 170px; height: 28px;}
    svg.main-logo-reifeswinger {width: 140px; height: 35px;}
    svg.main-logo-scambistimaturi {width: 165px; height: 30px;}
    svg.main-logo-sextapegermany {width: 75px; height: 35px;}
    svg.main-logo-xxxomas {width: 100px; height: 35px;}
  }
}

// RESPONSIVE - Mobile Small
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile_small} {
  .module-header_logo {
    // never more then width: 125px and height 35px
    svg.main-logo-lasfolladoras {width: 125px; height: 21px;}
    svg.main-logo-lacochonne {width: 125px; height: 21px;}
    //svg.main-logo-castingfrancais {same mobile}
    //svg.main-logo-lanovice {same mobile}
    //svg.main-logo-castingallaitaliana {same mobile}
    svg.main-logo-hausfrauficken {width: 125px; height: 25px;}
    svg.main-logo-deutschlandreport {width: 125px; height: 21px;}
    svg.main-logo-reifeswinger {width: 120px; height: 30px;}
    svg.main-logo-scambistimaturi {width: 120px; height: 30px;}
    //svg.main-logo-sextapegermany {same mobile}
    //svg.main-logo-xxxomas {same mobile}
  }
}


